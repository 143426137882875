import React, { useEffect, useState } from "react";
import $ from "jquery";
import ShowAlert from "../../components/ShowAlert";
import queryString from "query-string";
import ImportReturnFromExcel from "./ImportDetailFromExcel/ImportReturnFromExcel";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import deleteIcon from "../../images/del-icon.png";
import exportReturnIcon from '../../images/exceldownload4.png';
import { selectHeaderData } from "../header/headerSlice";
import { downloadExcelTemplateSalary, exportSalaryReturn } from "../../api/deductorAPI";
import LoadingIndicator from "../../components/loadingIndicator/LoadingIndicator";
import { CustomCellList } from "../../components/grid/CustomCell";
import {
  formatAccountsNumber,
  formatNumber,
  sortChange,
} from "../../utils/UtilityFunctions";
import {
  deleteEmployeeSalary,
  getEmployeeSalaryList,
  getEmployeeSalarySummary,
} from "../../api/EmployeeApi";
import { ColumnMenu } from "../../components/grid/ColumnMenuFrontEnd";
import ReturnFlowTimeLine from "../deducteeTransactions/ReturnFlowTimeLine";
import EditSalaryDetail from "./EditSalaryDetail";
import AddSalaryDetail from "./AddSalaryDetail";
import ViewSalaryDetailPopup from "./ViewSalaryDetailPopup";
import ConfirmPopup from "../../components/ConfirmPopup";
import { downloadRegularReturnErrorFileV2 } from "../../api/returnAPI";
import { HEADER_FILE_NAME } from "../../app/constants";
import EditDeductee from "../deducteeList/EditDeductee";
import "./SalaryAnnexure.scss";
import { Spinner } from "react-bootstrap";
import DownloadFile from "../../components/DownloadFile";
import { getCurrentFinancialYear } from "../form3CD/Form3CDUtilityFunctions";
import { organizationTypeHandler } from "../../utils/OrganizationTypeHandler";
import { getAuthToken } from "../../utils/AuthToken";

const SalaryAnnexure2 = () => {

  const history = useHistory();
  const location = useLocation();
  const searchStr = location.search;
  const params = queryString.parse(location.search);
  const headerDataDetails = useSelector(selectHeaderData);

  const LIMIT = 17;
  let financialYear = getCurrentFinancialYear();
  if (headerDataDetails.financial_year_formatted) {
    const getYearIndex = headerDataDetails.financial_year_formatted.split("-");
    if (getYearIndex[0]) financialYear = parseInt(getYearIndex[0]);
  }

  const [cursor, setCursor] = useState({
    current_page: 1,
    next_page_url: null,
  });

  const authToken = getAuthToken(true);
  
  const isDownloadExcel = organizationTypeHandler(authToken, "download_excel");


  const [dataState, setDataState] = useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [filterPostData, setFilterPostData] = useState(null);
  const [salaryList, setSalaryList] = useState(null);
  const [salarySummary, setSalarySummary] = useState({});
  const [deleteData, setDeleteData] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showDedcuteeEditForm, setShowDedcuteeEditForm] = useState(true);
  const [deducteeId, setDeducteeId] = useState(false);
  const [scrollPagnation, setScrollPagnation] = useState(false);
  const [downloadExcelFile, setDownloadExcelFile] = useState(false);
  const [loading, setLoading] = useState({
    pageLoader: false,
    showMoreLoader: false,
    downloading: false
  });
  const [showPopups, setShowPopups] = useState({
    importReturnFromExcel: false,
    addSalaryPopup: false,
    editSalaryPopup: false,
    createDeducteePopup: false,
    viewSalaryPopup: false,
    salary_id: "",
  });

  // function --------------------------------------------------------------------------------------------------------------------
  const createHistoryUrl = (data_state = [], eventAction = "", action = "") => {
    let filterStr = `?deductor_id=${params.deductor_id}&return_id=${params.return_id}`;
    if (params.tab) {
      filterStr += `&tab=${params.tab}`;
    }

    if (params.action) {
      let all_status = params.action.split(",");
      let array_index = all_status.indexOf(action);
      if (array_index !== -1) {
        all_status.splice(array_index, 1);
      } else {
        all_status.push(action);
      }
      filterStr +=
        all_status.length > 0 ? `&action=${all_status.join(",")}` : "";
    } else {
      if (action) {
        filterStr += `&action=${action}`;
      }
    }
    if (data_state.filter) {
      filterStr += `&filters=${btoa(
        JSON.stringify(data_state.filter.filters)
      )}`;
    }

    if (eventAction === "filter") {
      // summary api call when user event action filter
      // add flag in url
      filterStr += `&summary=true`;
    }

    if (data_state.sort && data_state.sort.length > 0) {
      filterStr += `&sort=${btoa(JSON.stringify(data_state.sort))}`;
    }
    history.push(filterStr);
  };

  const isColumnActive = (field) => {
    let active = false;
    if (dataState.filter) {
      dataState.filter.filters.map((filter, index) => {
        if (filter.filters[0].field === field) {
          active = true;
        }
        return true;
      });
    }
    return active;
  };

  const renderSubTextTitle = (title, field, subText, className) => {
    return (
      <div className="column_heading">
        <span onClick={() => sortChange(dataState, field, dataStateChange)}>
          {title}{" "}
        </span>{" "}
        {subText && (
          <div className={`${className ? className : "text-right"}`}>
            <span
              className="b2btbtrthtextclr"
              style={{ color: `#0000ff` }}
            >
              {subText}
            </span>
          </div>
        )}
      </div>
    );
  };

  const rowRender = (trElement, props) => {
    const dataItem = props?.dataItem ?? {};
    const key = trElement._owner.index;

    return (
      <tr key={key}>
        <td className="deductee_link" onClick={() => {
          setDeducteeId(dataItem.deductee_id);
          setShowDedcuteeEditForm(true);
        }}
        >
          {dataItem?.deductee_name ?? ""}
        </td>
        <td className="text-center">{dataItem?.pan ?? ""}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.net_salary_amtp ?? "")}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.total_deduction_vi_a_amtp ?? "")}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.total_net_taxable_income_amtp ?? "")}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.total_tax_computed_amtp ?? "")}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.total_tds_deducted_amtp ?? "")}</td>
        <td className="text-right">{formatAccountsNumber(dataItem?.excess_short_tds_amtp ?? "")}</td>
        <td className="row_action_btn">
          <span
            className="view_action cursor_pointer"
            onClick={() => {
              showPopups.viewSalaryPopup = true;
              showPopups.salary_id = dataItem.salary_id ?? "";
              setShowPopups({ ...showPopups });
            }}
          >
            View
          </span>
          <span
            className="edit_action cursor_pointer"
            onClick={() => {
              showPopups.editSalaryPopup = true;
              showPopups.salary_id = dataItem.salary_id ?? "";
              setShowPopups({ ...showPopups });
            }}
          >
            Edit
          </span>
          <span className="delete_action cursor_pointer">
            <img src={deleteIcon} alt="delete icon" width={16} onClick={() => {
              setShowConfirm(true);
              setDeleteData(props.dataItem);
            }} />
          </span>
        </td>
      </tr>
    );
  };

  // state updated function ------------------------------------------------------------------------------------------------

  const getParamsHistoryUrl = () => {
    const postData = {
      deductor_id: params.deductor_id,
      return_id: params.return_id,
    };

    if (params.sort) {
      dataState.sort = JSON.parse(atob(params.sort));
      postData.sort = JSON.parse(atob(params.sort));
    }

    if (params.filters) {
      dataState.filter = { filters: [] };
      dataState.filter.filters = JSON.parse(atob(params.filters));
      postData.filters = JSON.parse(atob(params.filters));
    }

    if (params.page) {
      postData.page = params.page;
    }

    if (params.action) {
      postData.action = params.action.split(",");
    }

    postData.limit = params.limit ?? LIMIT;
    setDataState(dataState);
    setFilterPostData(postData);
    return postData;
  };

  const importExelError = (data) => {
    showPopups.importReturnFromExcel = false;
    setShowPopups({ ...showPopups });
  };

  const showExcelPopup = () => {
    showPopups.importReturnFromExcel = true;
    setShowPopups({ ...showPopups });
  };

  const addNewRow = (info = false) => {
    showPopups.addSalaryPopup = true;
    showPopups.salary_id = info?.salary_id ?? "";
    setShowPopups({ ...showPopups });
  };

  const dataStateChange = (data_state) => {
    // Track user current action
    let eventAction = "";

    // Check if the filter state has changed
    if (JSON.stringify(dataState.filter) !== JSON.stringify(data_state.filter)) {
      eventAction = "filter";
    }
    // Check if the sort state has changed
    else if (JSON.stringify(dataState.sort) !== JSON.stringify(data_state.sort)) {
      eventAction = "sort";
    }

    // Update the component's state with the new data state
    setDataState(data_state);

    // Log the action or update the URL based on the action type
    createHistoryUrl(data_state, eventAction);
  };


  // start api --------------------------------------------------------------------------------------------------------------
  const downloadExcelTemp = async (isDetailTemplate) => {
    setLoading({ ...loading, pageLoader: true });
    try {
      const result = await downloadExcelTemplateSalary(isDetailTemplate);
      const csiData = new Blob([result?.data], { type: "application/xls" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(csiData);
      let formType = headerDataDetails.form_type;
      const fileName = result?.headers?.[HEADER_FILE_NAME] ?? "ExpressTDS_" + formType + "_Template.xlsx";
      link.setAttribute(
        "download",
        fileName
      );
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.error("Error in export: ", err);
    } finally {
      setLoading({ ...loading, pageLoader: false });
    }
  };



  const getSummary = async (data) => {
    try {
      const summaryResult = await getEmployeeSalarySummary(data);
      setSalarySummary(summaryResult.data ?? {});
    } catch (err) {
      if (err?.message) {
        setError(err?.message?.[0] ?? err.message);
      } else {
        setError(err);
      }
    }
  };

  const getEmployeeData = async (data, showMoreFlag) => {
    if (showMoreFlag) {
      loading.showMoreLoader = true;
    } else {
      loading.pageLoader = true;
    }
    setLoading({ ...loading });
    try {
      const listResult = await getEmployeeSalaryList(data);

      if (listResult.data.cursor.current_page > 1) {
        const currentSalaryList = salaryList ? salaryList : [];
        setSalaryList(currentSalaryList.concat(listResult.data.list));
      } else {
        setSalaryList(listResult?.data?.list ?? []);
      }
      setCursor(listResult.data.cursor);
    } catch (err) {
      setError(err.message);
    } finally {
      if (showMoreFlag) {
        loading.showMoreLoader = false;
      } else {
        loading.pageLoader = false;
      }
      setLoading({ ...loading });
      setScrollPagnation(false);
      setFilterPostData(data);
    }
  };

  const deleteSalary = async () => {
    setShowConfirm(false);
    setLoading(true);
    try {
      await deleteEmployeeSalary(params.deductor_id, deleteData.salary_id);
      getEmployeeData(filterPostData);
    } catch (err) {
      setError(err.message);
    }
    setLoading(false);
    setDeleteData(false);
  };

  const downloadErrorFile = async () => {
    loading.pageLoader = true;
    setLoading({ ...loading });
    try {
      let payload = `?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}`;
      const result = await downloadRegularReturnErrorFileV2(payload);
      const csiData = new Blob([result?.data], { type: 'application/xls' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(csiData);
      const file_name = result.headers?.[HEADER_FILE_NAME] ?? `ExpressTDS-ReturnErrorData.xlsx`;
      link.setAttribute('download', file_name);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      setError(err);
    } finally {
      loading.pageLoader = false;
      setLoading({ ...loading });
    }
  };


  const getScrollbarHeight = (el) => {
    return el.scrollHeight
  };

  $(function () {
    $(".e-invoicegridscrol .k-grid-content").scroll(function () {
      if (getScrollbarHeight(document.querySelector('.e-invoicegridscrol .k-grid-content')) < $(".e-invoicegridscrol .k-grid-content").scrollTop() + $(".e-invoicegridscrol .k-grid-content").height() + 3) {
        if (!scrollPagnation && cursor?.next_page > 1) {
          setScrollPagnation(true);
        }
      }
    });
  });

  useEffect(() => {
    const paramWithFilter = queryString.parse(searchStr);
    const postData = getParamsHistoryUrl();
    if (!salaryList || paramWithFilter.summary) {
      getSummary(postData);
    }
    getEmployeeData(postData);
    // eslint-disable-next-line
  }, [searchStr]);

  useEffect(() => {
    if (cursor?.next_page > 1) {
      const postData = getParamsHistoryUrl();
      postData.page = cursor.next_page;
      getEmployeeData(postData, true);
    }
    // eslint-disable-next-line
  }, [scrollPagnation]);

  return (
    <>
      <div>
        <ShowAlert
          error={error}
          success={success}
          onClose={() => {
            setError(null);
            setSuccess(null);
          }}
        />
        <ConfirmPopup
          show={showConfirm}
          handleProceed={() => deleteSalary()}
          confirmText={`Are you sure that you want to delete the salary data for the ${deleteData?.deductee_name} ? Please note that this process is irreversible. Click Delete button to continue.`}
          confirmButtonText="Delete"
          handleClose={() => {
            setDeleteData(false);
            setShowConfirm(false);
          }}
        />

        {showPopups.viewSalaryPopup && (
          <ViewSalaryDetailPopup
            salaryId={showPopups?.salary_id}
            returnId={params.return_id}
            deductorId={params.deductor_id}
            onClose={() => {
              showPopups.viewSalaryPopup = false;
              setShowPopups({ ...showPopups });
            }}
          />
        )}

        {showPopups?.editSalaryPopup && (
          <EditSalaryDetail
            salaryId={showPopups.salary_id}
            deductorId={params.deductor_id}
            returnId={params.return_id}
            showPopups={showPopups}
            onClose={(isFetchApiCall) => {

              showPopups.editSalaryPopup = false;
              setShowPopups({ ...showPopups });

              if (isFetchApiCall) {
                getEmployeeData(filterPostData);
              }

            }}
          />
        )}

        {showPopups?.addSalaryPopup && (
          <AddSalaryDetail
            deductorId={params.deductor_id}
            returnId={params.return_id}
            showPopups={showPopups}
            onClose={(isFetchApiCall) => {
              showPopups.addSalaryPopup = false;
              setShowPopups({ ...showPopups });

              if (isFetchApiCall) {
                getEmployeeData(filterPostData);
              }

            }}
            parentGetListApi={() => {
              getEmployeeData(filterPostData);
            }}
          />
        )}

        {downloadExcelFile &&
          <DownloadFile
            apiFunction={exportSalaryReturn}
            setDownloadLoading={(val) => {
              loading.downloading = val;
              setLoading({ ...loading });
            }}
            setError={setError}
            fileName={'ExpressTDS-SalaryAnnexure-II'}
            setDownloadExcelFile={setDownloadExcelFile}
            sessionStorageKey={`export-return-ref-key-${params?.deductor_id}-${params?.return_id}-${params?.financial_year}`}
            params={`?deductor_id=${params?.deductor_id}&return_id=${params?.return_id}&financial_year=${financialYear}`}
            handleSuccessProceed={() => {
              setDownloadExcelFile(false);
            }}
            handleErrorProceed={(msg) => {
              setDownloadExcelFile(false);
            }}
          />
        }

        <ImportReturnFromExcel
          show={showPopups.importReturnFromExcel}
          handleClose={(refresh) => {
            showPopups.importReturnFromExcel = false;
            setShowPopups({ ...showPopups });
            if (refresh) {
              history.go();
            }
          }}
          deductorId={params.deductor_id}
          returnId={params.return_id}
          importExelError={importExelError}
          downloadExcelTemp={downloadExcelTemp}
          headingtxt={"SalaryExcel"}
        />

        {showDedcuteeEditForm
          ? (
            <EditDeductee
              onClose={(reload, msg = '') => {
                setShowDedcuteeEditForm(false);
                if (msg) {
                  setSuccess(msg);
                }
                if (reload) {
                  setTimeout(() => {
                    getEmployeeData(filterPostData);
                  }, 300);
                }
              }}
              deductorId={params.deductor_id}
              deducteeId={deducteeId}
              deducteeInfo={false}
              headerNotUpdate={true}
            />
          )
          : null}

        {loading.pageLoader ? <LoadingIndicator /> : null}
        <div className="container-fluid">
          <ReturnFlowTimeLine
            returnId={params.return_id}
            deductorId={params.deductor_id}
            currentPage={`SalaryAnnexure`}
            headerDataDetails={headerDataDetails}
          />
        </div>
        <div className="col-md-12 pt-1">
          <button className="btn btn-default salary_taxreg_downloaderrrecord" style={{ marginLeft: "auto" }} onClick={() => downloadErrorFile()} >Download Error Record</button>

          {isDownloadExcel &&
            <button className="btn btn-default salary_taxreg_downloaderrrecord mr-1" onClick={() => setDownloadExcelFile(true)} title='Export Salary Details'>
              <span className="pr-1">
                {
                  loading.downloading
                    ? <><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />{' '}</>
                    : <img src={exportReturnIcon} width="16" alt="Export Return" />
                }
              </span>
              Download
            </button>
          }
        </div>

        <div className="salary_detail_annexure container-fluid mt-2">
          <div className="col-md-12">
            <div className="card mt-1">
              <div className="table-responsive-xl table-responsive-lg table-responsive-md table-responsive-sm table-responsive-xs">
                <Grid
                  className="table table-striped salary_detail_grid e-invoicegridscrol mb-2"
                  style={{ maxHeight: "485px", overflowX: "auto" }}
                  data={salaryList}
                  {...dataState}
                  rowRender={rowRender}
                  onDataStateChange={(event) =>
                    dataStateChange(event.dataState)
                  }
                  filterOperators={{
                    text: [
                      {
                        text: "grid.filterContainsOperator",
                        operator: "contains",
                      },
                    ],
                    numeric: [
                      { text: "grid.filterGteOperator", operator: "gte" },
                      { text: "grid.filterLteOperator", operator: "lte" },
                      { text: "grid.filterLtOperator", operator: "lt" },
                      { text: "grid.filterGtOperator", operator: "gt" },
                      { text: "grid.filterEqOperator", operator: "eq" },
                      { text: "grid.filterNotEqOperator", operator: "neq" },
                    ],
                  }}
                  sortable={false}
                >
                  <Column
                    field="deductee_name"
                    headerClassName={`head_btn_pos ${isColumnActive("deductee_name") ? "active" : ""
                      }`}
                    title={
                      <CustomCellList
                        field="deductee_name"
                        addnewRow={true}
                        title={"Employee Name"}
                        dataState={dataState}
                        dataStateChange={dataStateChange}
                        subtextData={{
                          subText: salarySummary?.total_salary ?? 0,
                          subClass: "text-center",
                          titleNotShow: true,
                        }}
                        handleClick={() => addNewRow()}
                      />
                    }
                    width="250"
                    columnMenu={(props) => (
                      <ColumnMenu hideSecondFilter {...props} />
                    )}
                    sortable={false}
                  />

                  <Column
                    field="pan"
                    headerClassName={`head_btn_pos ${isColumnActive("pan") ? "active" : ""
                      }`}
                    title={renderSubTextTitle("PAN", "pan")}
                    width="100"
                    columnMenu={(props) => (
                      <ColumnMenu hideSecondFilter {...props} />
                    )}
                  />

                  <Column
                    field="net_salary_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("net_salary_amtp") ? "active" : ""
                      }`}
                    title={renderSubTextTitle(
                      "Net Salary",
                      "net_salary_amtp",
                      formatNumber(salarySummary?.sum_net_salary_amtp ?? 0),
                      ""
                    )}
                    filter="numeric"
                    width="150"
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />

                  <Column
                    field="total_deduction_vi_a_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("total_deduction_vi_a_amtp")
                      ? "active"
                      : ""
                      }`}
                    title={renderSubTextTitle(
                      "Ded VI-A",
                      "total_deduction_vi_a_amtp",
                      formatNumber(
                        salarySummary?.sum_total_deduction_vi_a_amtp ?? 0
                      )
                    )}
                    filter="numeric"
                    width={"150"}
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />

                  <Column
                    field="total_net_taxable_income_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("total_net_taxable_income_amtp")
                      ? "active"
                      : ""
                      }`}
                    title={renderSubTextTitle(
                      "Net Taxable Inc",
                      "total_net_taxable_income_amtp",
                      formatNumber(
                        salarySummary?.sum_total_net_taxable_income_amtp ?? 0
                      )
                    )}
                    filter="numeric"
                    width={"150"}
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />

                  <Column
                    field="total_tax_computed_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("total_tax_computed_amtp") ? "active" : ""
                      }`}
                    title={renderSubTextTitle(
                      "Total Tax",
                      "total_tax_computed_amtp",
                      formatNumber(
                        salarySummary?.sum_total_tax_computed_amtp ?? 0
                      )
                    )}
                    filter="numeric"
                    width={"150"}
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />
                  <Column
                    field="total_tds_deducted_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("total_tds_deducted_amtp") ? "active" : ""
                      }`}
                    title={renderSubTextTitle(
                      "TDS Ded",
                      "total_tds_deducted_amtp",
                      formatNumber(
                        salarySummary?.sum_total_tds_deducted_amtp ?? 0
                      )
                    )}
                    filter="numeric"
                    width={"150"}
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />
                  <Column
                    field="excess_short_tds_amtp"
                    headerClassName={`head_btn_pos ${isColumnActive("excess_short_tds_amtp") ? "active" : ""
                      }`}
                    title={renderSubTextTitle(
                      "Excess/Short TDS",
                      "excess_short_tds_amtp",
                      formatNumber(
                        salarySummary?.sum_excess_short_tds_amtp ?? 0
                      )
                    )}
                    filter="numeric"
                    width={"150"}
                    columnMenu={(props) => <ColumnMenu {...props} />}
                  />
                  <Column
                    field="editIcon"
                    sortable={false}
                    title="Actions "
                    width={"100"}
                  />
                </Grid>

                <div className="row">
                  <div className="col-md-12">
                    <form>
                      <div className={`col-md-12 text-center`}>
                        {loading.showMoreLoader
                          &&
                          (
                            <span style={{ color: '#31719b' }}>
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                              {' '}
                              Loading...
                            </span>
                          )
                        }
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- left side import box --> */}
      <div className="salaryannexure_leftsidepopupbox " data-toggle="modal" data-target="#tdsreimport_popup" >
        <div className="challans_AnnouncementsIcon" data-toggle="modal" data-target="#updatebooks2adatam">
          <div className="challans_Announcements">
            <div className="challans_Announcements-wrapper challans_has-unseen-items Dropdown">
              <div className="Dropdown-toggle Button">
                <div className="challans_Announcements-bubble">&nbsp;</div>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" className="svg-inline--fa fa-bullhorn fa-w-18 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" style={{ color: 'rgb(255, 255, 255)', fontSize: '11px', transform: 'rotate(-38deg)' }}><path fill="currentColor" d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"></path>
                </svg>
              </div>


            </div>
          </div>
        </div>
        <div className="mb-0 btn challans_textfs" type="button" onClick={() => {
          showExcelPopup()
        }}>
          Import Salary Details
        </div>
      </div>
    </>
  );
};

export default SalaryAnnexure2;
