export function organizationTypeHandler(authToken, validate) {

  if (!authToken) {
    console.error("Invalid token");
    return false;
  }

  try {
    const decodedString = atob(authToken.split(".")[1]); // Decoding the token - to decode the base64-encoded string
    const decodedData = JSON.parse(decodedString); // Check if the decoded string is an object or an array
    const decodedSubData = JSON.parse(decodedData?.sub);

    if (Array.isArray(decodedSubData) && decodedSubData?.length > 0) {
      // Check if decodedData is an object or an array
      switch (validate) {
        case "type":
          return decodedSubData?.[8] === "CORPORATE" ? true : false;
        case "temp_detail":
          return decodedSubData?.[16].includes("sal_imp_v2");
        case "temp_lite":
          return decodedSubData?.[16].includes("sal_imp_v1");
        case "file_size_lite":
          return decodedSubData?.[17]?.sal_imp_v1_szmb;
        case "file_size_detail":
          return decodedSubData?.[17]?.sal_imp_v2_szmb;
        case "download_excel":
          return decodedSubData?.[16].includes("sal_exp_v2");
        case "show_detail_or_lite_view":
          return decodedSubData?.[16].includes("sal_v2");
        default:
          return false;
      }
    }

    return false; // If no organization type is found, return false
  } catch (error) {
    console.error("errrrr", error);
    return false;
  }
}
