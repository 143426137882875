/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCircle } from '@fortawesome/free-solid-svg-icons';
import logouticon from '../../images/loginicon/logouticon.png';
import callblueIcon from '../../images/call-blue.png';
import callIcon from '../../images/call.png';
import { BASE_PATH, LOGO, initialHeaderData, DEDUCTOR_CATEGORIES, DASHBOARD_PATH, DIFF_LAST_QTRSYNC_DAYS, FORM_3CD_START_YEAR } from '../../app/constants';
import { selectHeaderData, setHeader } from './headerSlice';
import { capitalizeFirstLetter, getCurrentFinancialYear, getCurrentFinancialYearByQuarter, getDaysDifference, getDeductorId, getFinancialYearsList, redirectToLoginPage, syncNowNotShow } from '../../utils/UtilityFunctions';
import NumericInput from '../../components/NumericInput';
import { addCallMe, getTanInfo } from '../../api/profileAPI';
import LoadingIndicator from '../../components/loadingIndicator/LoadingIndicator';
import ShowAlert from '../../components/ShowAlert';
import Extdslogo from '../../images/logo/extdslogo.png';
import ExtdsDiwalilogo from '../../images/logo/extdslogo_diwali.svg';
import Abv1logo from '../../images/logo/abv1logo.svg';
import Abv2logo from '../../images/logo/abv2logo.svg';
import IndiaCAlogo from '../../images/logo/icalogo.svg';
import { LOGIN_PATHS } from '../../App';
import editIcon from "../../images/edite02.png";
import tracesIcon from "../../images/traces_icon.png";
import itdIcon from "../../images/itd_icon.png";
import queryString from 'query-string';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import TanInfoAnimation from "../../components/TanInfoAnimation/TanInfoAnimation";
import DeductorSyncClient from '../createDeductor/DeductorSyncClient';
import './Header.css';
import InsightsGovtPortal from '../../components/kdkConnector/InsightsGovtPortal';
import TracesGovtPortal from '../../components/kdkConnector/TracesGovtPortal';
import ConnectorAdapter from '../../components/kdkConnector/ConnectorAdapter';
import EFilingConnectorForImportChallan from '../challanList/EFilingConnectorForImportChallan';
import ImportChallan from '../challanList/ImportChallan';
import TracesConnector from '../../components/kdkConnector/TracesConnector';
import SubscribePopup from '../Plans/SubscribePopup';
import $ from 'jquery';
import DraggableContent from '../../components/grid/DraggableContent';
import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { getDeductorEfilingCredentials } from '../../api/deductorAPI';
import { getConsoRequestConfigStatus, getSyncStatusWithoutSettingHeader, updateConsoRequestConfigStatus } from '../../api/ReturnRegister';
import { clearAuthToken } from '../../utils/AuthToken';
import { getFinancialYearsList as getfylist } from '../form3CD/Form3CDUtilityFunctions';

// Modify Logo, as per Cobrand identified. If not, default to Express TDS Logo
let logo_touse = Extdslogo;
const logo_uc = LOGO.toUpperCase();
if (logo_uc === "ABV1") {
  logo_touse = Abv1logo;
} else if (logo_uc === "ABV2") {
  logo_touse = Abv2logo;
} else if (logo_uc === "EXTDSDIWALI") {
  logo_touse = ExtdsDiwalilogo;
} else if (logo_uc === "ICAIV1") {
  logo_touse = IndiaCAlogo;
}

export const SYNC_NOW_NOTSHOW = [
  `${BASE_PATH}edit-deductor/:deductorId`,
  `${BASE_PATH}edit-deductor`
];


export default function Header() {
  const headerData = useSelector(selectHeaderData);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const searchStr = location.search;
  const pathName = location.pathname;
  const params = queryString.parse(location.search);
  const pathDeductor = getDeductorId(pathName);

  const [callMe, setCallMe] = useState(headerData.mobile_number);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [filterYear, setFilterYear] = useState({});
  const [showTanInfoPopup, setshowTanInfoPopup] = useState(false);
  const [tanInfo, setTanInfo] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [latestSyncStatus, setLatestSyncStatus] = useState(null);
  const [userCreds, setUserCreds] = useState("");
  const [checkItPortal, setCheckItPortal] = useState(false);
  const [checkTracesPortal, setCheckTracesPortal] = useState(false);
  const [syncStart, setSyncStart] = useState(false);
  const [collapseShow, setCollapseShow] = useState(false);
  const [consoReqConfig, setConsoReqConfig] = useState(false);

  const [firstTime, setFirstTime] = useState(false);

  const financial_year_formatted = headerData.financial_year_formatted ? parseInt(headerData.financial_year_formatted.split("-")?.[0] ?? 0) : "";

  const [port, setPort] = useState(0);
  const [captchaRef, setCaptchaRef] = useState(null);
  const [showImportChallan, setShowImportChallan] = useState(false);
  const [startEfilingProcess, setStartEfilingProcess] = useState(false);
  const [startTracesProcess, setStartTracesProcess] = useState(false);
  const [showSubscribePopup, setShowSubscribePopup] = useState(false);

  const isFYDropdownShow = matchPath(location.pathname, { path: DASHBOARD_PATH, exact: true, strict: false });
  const SyncShow = syncNowNotShow(location.pathname);
  // temprory commit localstorage function 
  // const syncClient = localStorage.getItem(`sync-update-${headerData.mobile_number}`) ? JSON.parse(localStorage.getItem(`sync-update-${headerData.mobile_number}`)) : {}


  const planNotShowPath = [
    `${BASE_PATH}plans`,
    `${BASE_PATH}profile`,
    `${BASE_PATH}subscription`,
    `${BASE_PATH}user-profile`,
    `${BASE_PATH}login`,
    `${BASE_PATH}signup`,
    `${BASE_PATH}forgetpass`,
  ];

  const FORM3CDPATH = [
    `${BASE_PATH}form3CD/report-clause-34a`,
    `${BASE_PATH}form3CD/report-clause-34b`,
    `${BASE_PATH}form3CD/report-clause-34c`,
  ]

  const FORM3CDALLPATH = [
    `${BASE_PATH}form3CD/incomplete-deductors`,
    `${BASE_PATH}form3CD/return-summary`,
    `${BASE_PATH}form3CD/report-clause-34a`,
    `${BASE_PATH}form3CD/report-clause-34b`,
    `${BASE_PATH}form3CD/report-clause-34c`,
  ]
  const addCallMeData = async () => {

    if (callMe >= 5999999999 && callMe <= 9999999999) {
      setLoading(true);
      const data = {
        mobile_no: callMe,
      };
      try {
        const result = await addCallMe(data);
        setSuccess(result.message);
      } catch (err) {
        if (err.message) setError(err.message);
        else setError(err);
        console.error('Error in updating password: ', err);
      }
      setLoading(false);
    }
    else {
      setError("Invalid Mobile Number");
    }
  };

  const changeYearFilter = (value) => {
    setFilterYear(value);
    let searchStr = '';
    Object.keys(params).forEach((key) => {
      if (key && key !== 'financial_year') {
        searchStr = `${searchStr}&${key}=${params[key]}`;
      }
    });
    history.push(`?financial_year=${value.value}&${searchStr}`)
  };

  const changeYear3cdFilter = (value) => {
    setFilterYear(value);
    let searchStr = '';
    Object.keys(params).forEach((key) => {
      if (key && key !== 'fy') {
        searchStr = `${searchStr}&${key}=${params[key]}`;
      }
    });
    history.push(`?fy=${value.value}&${searchStr}`)
  };

  const logOut = (e) => {
    e.preventDefault();
    clearAuthToken();
    localStorage.removeItem('pincode_available');
    dispatch(setHeader(initialHeaderData));
    redirectToLoginPage();
  };

  const getItdCreditional = async (deductor_Id) => {
    setLoading(true);
    const creds = {
      'itdportal_username': headerData.tan,
      'itdportal_password': "",
      "deductor_id": deductor_Id
    }
    try {
      const result = await getDeductorEfilingCredentials(`?deductor_id=${deductor_Id}`, false);
      if((result?.data?.itdportal_username ?? '') !== '') {
        creds.itdportal_username = result?.data?.itdportal_username;
      }      
      creds.itdportal_password = result?.data?.itdportal_password ?? ''

    } catch (err) {
      // console.error(err);
    } finally {
      setLoading(false);
      setUserCreds((prev) => {
        prev = creds;
        return { ...prev };
      })
    }
  }


  const getTanInfoData = async () => {

    let pathParams = "";
    if (params?.deductor_id) {
      pathParams = { deductorId: params.deductor_id };
    } else {
      pathParams = getDeductorId(location.pathname)
    }

    try {

      const result = await getTanInfo(String(pathParams.deductorId));
      if (result.data) {
        setTanInfo(result?.data?.data ?? []);
        setshowTanInfoPopup(true);
      }

    } catch (err) {
      console.error('Error in fetching TAN Info: ', err);
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      setLoading(false);
    }
  };

  const syncStatusOnServer = async (deductorId) => {
    try {
      const result = await getSyncStatusWithoutSettingHeader(deductorId);
      setLatestSyncStatus(result?.data ?? null);
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    }
  }

  const getConsoReqStatus = async () => {
    try {
      const result = await getConsoRequestConfigStatus();
      setConsoReqConfig(result?.data?.conso_req_for_ret_proc_with_def_only ?? 0);
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    }
  }

  const updateConsoReqStatus = async (status) => {
    const payload = {
      conso_req_for_ret_proc_with_def_only: status
    }
    setLoading(true);
    try {
      const result = await updateConsoRequestConfigStatus(payload);
      if (result?.data?.data) {
        setConsoReqConfig(status);
      }
      setSuccess(result?.data?.message ?? "")
    } catch (err) {
      if (err.message) setError(err.message);
      else setError(err);
    } finally {
      setLoading(false);

    }
  }

  const indiviualQrtSyncwaringshow = (updated_date) => {
    const sync_date = updated_date ? moment(updated_date?.substr(0, 10), 'DD/MM/YYYY').format('YYYY-MM-DD') : null
    const currentDate = moment(new Date()).format('YYYY-MM-DD');

    var diffDays = sync_date ? getDaysDifference(sync_date, currentDate) : null;

    if (diffDays) {
      return Math.floor(diffDays);
    }
    else return 0;
  }


  useEffect(() => {
    if (latestSyncStatus?.sync_status === null && pathName === DASHBOARD_PATH && headerData?.plan_valid) {
      const deductorId = params?.deductor_id ?? "";
      getItdCreditional(deductorId)
      setFirstTime(true);
    }

    // eslint-disable-next-line
  }, [latestSyncStatus, headerData?.plan_valid])

  useEffect(() => {
    const isFYDropdownShow = matchPath(pathName, { path: DASHBOARD_PATH, exact: true, strict: false });

    if (firstTime && syncStart && isFYDropdownShow) {

      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        getTanInfoData();
      }, 10000);

    }
    // eslint-disable-next-line
  }, [firstTime, syncStart])


  useEffect(() => {
    setCallMe(headerData.mobile_number);
    // eslint-disable-next-line
  }, [headerData]);



  useEffect(() => {

    const parameter = queryString.parse(searchStr);
    const isFYDropdownShow = matchPath(pathName, { path: DASHBOARD_PATH, exact: true, strict: false });
    setUserCreds(null)

    if (isFYDropdownShow) {
      let param_year = !isNaN(parameter.financial_year) ? parseInt(parameter.financial_year) : getCurrentFinancialYear();
      // set year and month dropdown
      const year_list = getFinancialYearsList(param_year);

      setYearList(year_list);
      if (param_year) {
        const selected_year = year_list.filter((year) => parseInt(year.value) === param_year);
        setFilterYear(selected_year[0]);
      } else {
        setFilterYear(getCurrentFinancialYearByQuarter());
      }
    }else if(FORM3CDPATH.includes(pathName)){

     // new dropdown year list only Clause 34-Form 3CD Annex
      const year_list = getfylist(FORM_3CD_START_YEAR);

      setYearList(year_list);
      if (params.fy) {
        const selected_year = year_list.filter((year) => year.value === params.fy);
        console.log("fy",params.fy)
        console.log("fyselect",selected_year, year_list)
        setFilterYear(selected_year[0]);
      } else {
        setFilterYear(getCurrentFinancialYearByQuarter());
      }

    }
    if (showTanInfoPopup) setshowTanInfoPopup(false);
    // eslint-disable-next-line
  }, [searchStr, pathName])

  

  useEffect(() => {
    if (params?.deductor_id && SyncShow) {
      syncStatusOnServer(params?.deductor_id)
    }
    getConsoReqStatus();
  }, [params?.deductor_id, SyncShow, searchStr])

  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return `${moment(date).format('DD/MM/YYYY')} ${strTime}`
  }

  useEffect(() => {
    if (headerData?.mobile_number && !headerData.plan_valid) {
      setShowSubscribePopup(true);
      $(document).ready(function () {
        setTimeout(() => {
          $('.planexpiredalert').click(function () {
            setShowSubscribePopup(true);
            return false
          });
        }, 500);

      });
    }
  }, [headerData?.mobile_number, headerData?.plan_valid, location.pathname])


  return (
    <header>
      {loading ? <LoadingIndicator /> : null}
      {!planNotShowPath.includes(location.pathname) && <SubscribePopup
        show={showSubscribePopup}
        handleClose={(msg = "") => {
          setShowSubscribePopup(false);
          if (msg) {
            setSuccess(msg);
          }
        }}
        history={history}
      />}

      <ShowAlert
        error={error}
        success={success}
        onClose={() => { setError(null); setSuccess(null); }}
      />

      {
        showImportChallan
          ? <ConnectorAdapter
            show={showImportChallan}
            handleClose={() => setShowImportChallan(false)}
            setPort={setPort}
            handleProceed={() => setStartEfilingProcess(true)}
            title={'Login at Income Tax Portal'}
            section={'EFILING'}
          />
          : null
      }

      {startEfilingProcess && port
        ?
        <EFilingConnectorForImportChallan
          deductorId={params?.deductor_id ?? ''}
          returnId={params.return_id ?? ''}
          handleProceed={() => {
            setStartTracesProcess(true);
            setStartEfilingProcess(false);
          }}
          port={port}
        />
        : null
      }

      {startTracesProcess && port
        ?
        <TracesConnector
          deductorId={params?.deductor_id ?? ''}
          returnId={params.return_id}
          handleProceed={() => {
            setShowImportChallan(false);
            setStartTracesProcess(false);
            setStartEfilingProcess(false);
          }}
          port={port}
          setCaptchaRef={setCaptchaRef}
          setVerifyCreds={() => null}
          creds={null}
        />
        : null
      }

      {captchaRef && port ? (
        <ImportChallan
          deductorId={params?.deductor_id ?? ''}
          returnId={params.return_id}
          port={port}
          captchaRef={captchaRef}
          setError={setError}
          statusKey={null}
          setStartImportOltasChallans={() => {
            setCaptchaRef(false)
          }}
        />
      ) : null
      }

      {showTanInfoPopup && (params.deductor_id || pathDeductor?.deductorId) &&
        (
          <TanInfoAnimation
            showTanInfoPopup={showTanInfoPopup}
            TanInfo={tanInfo}
            onHide={() => {
              setshowTanInfoPopup(false);
            }}
            firstTime={firstTime}
            setFirstTime={setFirstTime}
          />
        )}

      {userCreds && params.deductor_id &&
        <DeductorSyncClient
          syncDeductor={userCreds}
          deductorId={params?.deductor_id ?? ""}
          financial_year={filterYear?.value ?? financial_year_formatted}
          lastSyncStatus={() => {
            const status = {
              sync_status: "IN_PROGRESS",
              sync_updated_at: formatAMPM(new Date()),
              updated_at_unixts: new Date().getTime()
            }
            setLatestSyncStatus(status);
            // temprory commit localstorage function 
            // if (syncClient[headerData.tan]) {
            //   syncClient[headerData.tan] = false;
            //   localStorage.setItem(`sync-update-${headerData.mobile_number}`, JSON.stringify(syncClient));
            // }
          }}
          tracesSyncStart={true}
          setSyncStart={setSyncStart}
          latestSyncStatus={latestSyncStatus}
          setFirstTime={setFirstTime}
        />
      }

      {checkItPortal && (params.deductor_id || pathDeductor?.deductorId) &&
        <InsightsGovtPortal
          syncDeductor={params?.deductor_id ?? pathDeductor.deductorId ?? ""}
          setCheckItPortal={setCheckItPortal}
          tan={headerData?.tan ?? ""}

        />
      }

      {checkTracesPortal && (params.deductor_id || pathDeductor?.deductorId) &&
        <TracesGovtPortal
          syncDeductor={params?.deductor_id ?? pathDeductor.deductorId ?? ""}
          setCheckTracesPortal={setCheckTracesPortal}
        />
      }


      <nav className="navbar newHeadercss">
        <div className="container-fluid">
          <div className="navbar-header col-5 col-md-5 col-sm-5 col-lg-5 col-xl-5 smpaddhead pl-0">
            <div style={{ display: "inline-flex" }}>
              <Link className="navbar-brand mainexplogo" to={BASE_PATH}>
                {
                  logo_touse === ExtdsDiwalilogo
                    /* For object tag, need to set pointer-events: none for clickable link. Ref: https://stackoverflow.com/a/17133804/2469308 */
                    ? <object style={{ pointerEvents: "none" }} type="image/svg+xml" data={ExtdsDiwalilogo} aria-labelledby="Express TDS" width="120" height="45"></object>
                    : <img src={logo_touse} alt="Express TDS" style={{ width: "100px" }} />
                }
              </Link>
              {headerData.deductor_name ? <ul className="tds_dashboard_headerleftsidebox">
                <li>
                  <div className="tds_dashboard_headerleftprofilefs" style={{ textAlign: "left", color: "rgb(49, 113, 155)", fontSize: "12px" }}>
                    <div className='header_compname_textoverflow' style={{ position: "relative", display: "inline-block", fontWeight: "700" }} title={headerData.deductor_name ?? ""}>{headerData.deductor_name}</div>
                    <br />
                    <span className=" tds_dashboard_headerleft_insight" >
                      <span className="profilesmfsize planexpiredalert" title={headerData.tan ?? ""} onClick={() => getTanInfoData()} style={{ cursor: "pointer" }}>
                        {headerData.tan}
                        <span className="tds_dashboard_headerleft_infoiconheader" title="TAN Insights">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z">

                            </path>
                          </svg>
                        </span>
                      </span>
                    </span>
                    <span style={{ padding: "10px 7px 6px 11px", color: "rgb(252, 125, 114)" }}>|</span>
                    <span title={DEDUCTOR_CATEGORIES?.[headerData?.deductor_category] ?? ""} style={{ paddingLeft: "0px", paddingRight: "0px" }}>{DEDUCTOR_CATEGORIES?.[headerData?.deductor_category] ?? ""}</span>
                    <span style={{ padding: "10px 7px 6px 5px", color: "rgb(252, 125, 114)" }}>|</span>
                    {SyncShow &&
                      <>
                        <Link to={`${BASE_PATH}edit-deductor/${params?.deductor_id ?? ""}`} target="_blank" className='planexpiredalert'>
                          <span className="pl-1 cursorpoit">
                            <img src={editIcon} className="editicon" title="Edit Deductor Info" alt='edit icon' width="16" />
                          </span>
                        </Link>
                        <span style={{ padding: "10px 7px 6px 5px", color: "rgb(252, 125, 114)" }}>|</span>
                      </>
                    }
                    {/* // :
                      // <span className="pl-1 cursorpoit">
                      //   <img src={editIcon} className="editicon" alt='edit icon' width="16" />
                      // </span>} */}

                    <span className="pl-1 cursorpoit">
                      <img src={tracesIcon} className="govticon planexpiredalert"
                        alt="traces icon" width="18"
                        title="Direct login to TRACES"
                        onClick={() => {
                          setCheckTracesPortal(true);
                        }} />
                    </span>
                    <span style={{ padding: "10px 7px 6px 5px", color: "rgb(252, 125, 114)" }}>|</span>
                    <span className="pl-1 cursorpoit">
                      <img className="it-portal-login govticon planexpiredalert"
                        src={itdIcon} alt="it-port icon" title="Direct login to ITD"
                        width="18"
                        onClick={() => {
                          setCheckItPortal(true);
                        }}
                      />
                    </span>
                  </div>
                </li>
              </ul>
                : FORM3CDALLPATH.includes(pathName) ?
                  <ul className="tds_dashboard_headerleftsidebox">
                    <li>
                      <div className="tds_dashboard_headerleftprofilefs" style={{ textAlign: "left", color: "rgb(49, 113, 155)", fontSize: "12px" }}>
                        <div className='header_compname_textoverflow' style={{ position: "relative", display: "inline-block", fontWeight: "700" }}
                          title={params?.deductor_name ?? ""}>{params?.deductor_name ?? ""}</div>
                        <br />
                        <span className=" tds_dashboard_headerleft_insight" >
                          <span className="profilesmfsize planexpiredalert" title={params?.pan ?? ""}>
                            {params?.pan ?? ""}
                          </span>
                        </span>
                      </div>
                    </li>
                  </ul>
                  : null
              }
            </div>
          </div>
          <div className="col-7 col-md-7 col-sm-7 col-lg-7 col-xl-7 mt-0 smpaddhead" style={{ margin: 'auto' }}>
            <div className='dispinlinerightnav'>
              <ul className="headernavdropmd mb-0"
                style={{ marginRight: "3.8rem", display: 'inline-flex' }}
              >

                <li>
                  <div className="drodwnsynstatus">
                    <div
                      className="lipaddmanage"
                    >
                      {isFYDropdownShow ?
                        <>
                          <span className="dropdwonfy" >F.Y.</span><span className="dropdwonyearlist">
                            <DropDownList
                              data={yearList}
                              className="header_select headerselect drpdwn_width"
                              popupSettings={{ className: 'headerselectoption' }}
                              textField="text"
                              value={filterYear}
                              onChange={(e) => { changeYearFilter(e.target.value); }}
                              dataItemKey="value"
                              id="yearDropDownPopup"
                            />
                          </span>
                        </>
                        : 
                        FORM3CDPATH.includes(pathName) ?  // show dropdown only Clause 34-Form 3CD Annex - clause34(a), clause34(b), clause34(c) pages 
                          <>
                            <span className="dropdwonfy" >F.Y.</span><span className="dropdwonyearlist">
                              <DropDownList
                                data={yearList}
                                className="header_select headerselect drpdwn_width"
                                popupSettings={{ className: 'headerselectoption' }}
                                textField="text"
                                value={filterYear}
                                onChange={(e) => { changeYear3cdFilter(e.target.value); }}
                                dataItemKey="value"
                                id="yearDropDownPopup"
                              />
                            </span>
                          </>
                        :
                        headerData.financial_year_formatted ? (
                          <span className="formdetail">
                            {`F.Y. ${headerData.financial_year_formatted}`}
                            {' '}
                            &nbsp;|&nbsp;
                            {headerData.quarter}
                            {' '}
                            &nbsp;|&nbsp;
                            {headerData.form_type}
                            &nbsp;|&nbsp;
                            {capitalizeFirstLetter(headerData.return_type)}
                          </span>
                        ) : (params?.deductor_id || pathDeductor?.deductorId) && params?.financial_year && SyncShow &&
                        <span className="formdetail">
                          {/* {`F.Y. ${params.financial_year}-${parseInt(params.financial_year?.substr(2, 2)) + 1}`}
                        {' '} */}
                        </span>

                      }

                    </div>
                    {(params?.deductor_id) && SyncShow && <span className="tds_sync_status">
                      <span className="tds_boxalign">
                        {latestSyncStatus?.sync_updated_at ? `Last synced on: ${latestSyncStatus.sync_updated_at}` : `Sync Now`}
                        <span className='planexpiredalert' title={`${(latestSyncStatus?.sync_status === "IN_PROGRESS" || syncStart) ? "In Progress" : "Refresh Portal Data"}`} style={{ cursor: 'pointer' }}
                          onClick={() => {

                            const deductorId = params?.deductor_id ?? "";
                            if (latestSyncStatus?.sync_status !== "IN_PROGRESS") getItdCreditional(deductorId);

                          }}>
                          <FontAwesomeIcon icon={faSync} color="#31719b" className={`${(latestSyncStatus?.sync_status === "IN_PROGRESS" || syncStart) ? "rotate_icon" : ""}`} />
                        </span>
                      </span>
                    </span>}
                  </div>

                </li>

                <li className="dropdmenufr">

                  {!LOGIN_PATHS.includes(location.pathname) && (
                    <div className="userprodropd">
                      <ul className="">
                        <li className="nav-item dropdown userprodropdlist" style={{ top: headerData.financial_year_formatted ? '0px' : '0px' }}>

                          <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown" >
                            <span className="profile_img_bg dark_color_text">
                              <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-person-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              </svg>
                            </span>

                          </a>
                          <div className="dropdown-menu dropdown-menu-right" >
                            <div className="dropdown-item profileusr ">

                              <span className="black_color_text usertextoverflow" style={{ paddingLeft: '0px', fontSize: '12px', padding: '0rem', marginTop: '0rem', borderRadius: '0rem' }}>
                                <span className="pl-0" data-toggle="tooltip" data-placement="top" title={headerData.full_name}>  {headerData.full_name}</span>
                              </span>
                              <span className="activeusrp">
                                <FontAwesomeIcon icon={faCircle} />
                              </span>

                            </div>
                            <div className="dropdown-divider" />

                            <Button
                              className="dropdown-item"
                              onClick={(e) => {
                                history.push(`${BASE_PATH}profile`);
                              }}
                              style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}
                            >
                              Profile Settings
                            </Button>
                            <div className="dropdown-divider" />
                            <Button
                              className="dropdown-item"
                              onClick={() => {
                                history.push(`${BASE_PATH}subscription`);
                              }}
                              style={{ fontSize: '12px', padding: '0.2rem 0.5rem' }}
                            >
                              Subscription Plan
                            </Button>
                            {(headerData.user_role.toUpperCase() === 'OWNER') && (
                              <div className="dropdown-divider" />
                            )}

                            {(headerData.user_role.toUpperCase() === 'OWNER') && (
                              <Button
                                className="dropdown-item planexpiredalert"
                                onClick={() => {
                                  history.push(`${BASE_PATH}user-management`);
                                }}
                                style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}
                              >
                                User Management
                              </Button>
                            )}
                              <div className="dropdown-divider" />

                            <Button
                              className="dropdown-item planexpiredalert"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}
                            >
                              Conso Request Configuration
                              <span className={`headerconsorequestconfig ${!collapseShow ? "collapsed" : ""}`} data-toggle="collapse" onClick={() => setCollapseShow(!collapseShow)}
                                href="#headerconsorequestconfig_collap01" aria-expanded="false">
                              </span>
                            </Button>
                            <div id="headerconsorequestconfig_collap01" className={`in collapse ${collapseShow ? "show" : ""}`}>
                              <div className="dropdown-divider" />

                              <Button
                                className="dropdown-item planexpiredalert"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                style={{ fontSize: '0.8rem', padding: '0.2rem 0.5rem' }}
                              >
                                <span className="raisereqretprocesswithdef">
                                Raise only for Returns Processed with Default
                                <label className="switch listofreqconfigswitch">
                                  <input type="checkbox" checked={consoReqConfig} 
                                  onChange={() => {
                                    const status = consoReqConfig ? 0 : 1;
                                    updateConsoReqStatus(status)
                                  }}/>
                                  <span className="slider round listofreqconfiground" />
                                </label>
                                </span>
                                
                                
                              </Button>
                            </div>

                            <div className="dropdown-divider" />
                            <Button className="dropdown-item logdisinline" onClick={(e) => logOut(e)} style={{ fontSize: '0.8rem' }}>
                              Logout
                              <span className=" logicntopm">
                                <img className="" src={logouticon} alt="logout" />
                              </span>
                            </Button>

                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              </ul>
              {/* Start call me */}
              {!LOGIN_PATHS.includes(location.pathname) && (
                <ul className="" style={{ position: 'absolute', right: '0', top: "0", padding: "0" }}>
                  <li className="nav-item dropdown callmemainbox dropleft header_callmemainbox">
                    <span className="nav-link-btn callmebgclr planexpiredalert" id="navbardrop" data-toggle="dropdown">
                      <span className="callicon planexpiredalert"><img src={callIcon} alt="call" /></span>
                      <span className="calltext planexpiredalert">Call Me</span>
                    </span>
                    <div className="dropdown-menu header_cllmeinnerdown">
                      <div className="dropdown-item headerscetbborder">
                        <span className="callicon"><img src={callblueIcon} alt="call" /></span>
                        <span className="headertextclr"> Get a Call Back from Us </span>
                        <span className="closeiconright">
                          <i className="fa fa-times" aria-hidden="true"></i>
                        </span>
                      </div>
                      <div className="textboxinnersect">
                        <p className="contentarea">
                          Enter the Mobile No. on which you want to get a call back from us:
                        </p>
                        <div className="inputboxarea">
                          <NumericInput
                            type="text"
                            className="form-control inputcallme"
                            placeholder="Enter Mobile Number"
                            value={callMe}
                            maxLength={10}
                            onChange={(e) => setCallMe(e.target.value)}
                            id="call_me_input"
                          />
                        </div>
                        <div className="callmebtnbox">
                          <button className="btn btn-default callmedonebtn" onClick={() => addCallMeData()}> Done</button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>)}
              {/* End call me */}

            </div>

          </div>
        </div>
      </nav>
      {
        (indiviualQrtSyncwaringshow(latestSyncStatus?.sync_updated_at) >= DIFF_LAST_QTRSYNC_DAYS
          //  || (syncClient[headerData.tan] && indiviualQrtSyncwaringshow(latestSyncStatus?.sync_updated_at) > DIFF_LAST_SYNC_DAYS)
          || latestSyncStatus?.sync_updated_at === null) && params.deductor_id
        && <DraggableContent style={{position: 'fixed', zIndex: 100, bottom: '0px', left: '5%', maxHeight: 'fit-content'}}>
          <div className="sync_stripchallanfooter d-flex justify-content-center ">            
            <span className="text-center drc-border-ani">            
              <span className='exprit_expriebox_btnbox'>
                <span style={{ paddingLeft: '0px', left:'-8px', position:'relative', paddingTop: '0px', color: '#fc7d72', zIndex: 100}}><FontAwesomeIcon icon={faGripVertical}/></span>
                <span className=" ml-1">                 
                Challan balances are <span style={{color: '#fc7d72'}}>not updated</span>. You are advised to update the same by clicking the "Update" button. This would not hinder your work and will happen in the background.
                </span>
                <button className="updatebtn planexpiredalert" onClick={() => {
                  getItdCreditional(params?.deductor_id ?? "");
                  setFirstTime(false);
                }}>Update Now</button>
              </span>
              <svg><defs><filter id="glow"></filter></defs><rect className="react_attribute"></rect></svg>
            </span>
          </div>
        </DraggableContent>        
      }
    </header>
  );
}
